import React from "react";
import { withTranslation } from "react-i18next";

const services = [
  {
    count: 1,
    titleKey: "interior_design",
    descriptionKey: "interior_design_description",
    icon: require("./../../images/icon/planning.png"),
  },
  {
    count: 2,
    titleKey: "fit_out",
    descriptionKey: "fit_out_description",
    icon: require("./../../images/icon/hammer-1.png"),
  },
  {
    count: 3,
    titleKey: "manufacturing",
    descriptionKey: "manufacturing_description",
    icon: require("./../../images/icon/crane-1.png"),
  },
  {
    count: 4,
    titleKey: "maintenance",
    descriptionKey: "maintenance_description",
    icon: require("./../../images/icon/cogwheels.png"),
  },
  {
    count: 5,
    titleKey: "quality_management",
    descriptionKey: "quality_management_description",
    icon: require("./../../images/icon/calculations.png"),
  },
];

var img1 = require("./../../images/pattrens-light.png");

class Specialization4 extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <div
          className="section-full p-tb80 bg-white bg-repeat square_shape2 inner-page-padding"
          style={{ backgroundImage: "url(" + img1 + ")" }}
        >
          <div className="container" style={{ direction: "ltr" }}>
            {/* TITLE START */}
            <div className="section-head">
              <div className="mt-separator-outer separator-left">
                <div className="mt-separator">
                  <h2 className="text-uppercase sep-line-one ">
                    <span className="font-weight-300 text-primary">
                      {t("our_specialization")}
                    </span>
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                {services.map((item, index) => (
                  <div className="col-md-4 col-sm-6" key={index}>
                    <div className="mt-icon-box-wraper m-b30">
                      <div className="relative icon-count-2 bg-gray p-a30 p-tb50">
                        <span className="icon-count-number">{item.count}</span>
                        <div className="icon-md inline-icon m-b15 text-primary scale-in-center">
                          <span className="icon-cell">
                            <img src={item.icon} alt={t(item.titleKey)} />
                          </span>
                        </div>
                        <div className="icon-content">
                          <h4 className="mt-tilte m-b25">{t(item.titleKey)}</h4>
                          <p>{t(item.descriptionKey)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(Specialization4);
