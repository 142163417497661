import React from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Components from "./components/Components";
import ScrolToTop from "./components/Elements/ScrolToTop";
import Loader from "./components/Elements/Loader";

const App = () => {
  const lang = localStorage.getItem("language") || "en";
  i18n.changeLanguage(lang); // Set the initial language based on localStorage

  return (
    <I18nextProvider i18n={i18n}>
      <div className="App">
        <Components />
        <ScrolToTop />
        <Loader />
      </div>
    </I18nextProvider>
  );
};

export default App;
