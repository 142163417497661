import React from "react";
import CountUp from "react-countup";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";

var img1 = require("./../../images/video-bg.jpg");

const OurValue = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="section-full mobile-page-padding p-t80 p-b30 bg-white">
        <div className="container">
          <div className="section-content">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                {/* TITLE START */}
                <div className="section-head">
                  <div className="mt-separator-outer separator-left">
                    <div className="mt-separator">
                      <h2 className="text-uppercase sep-line-one">
                        <span className="font-weight-300 text-primary">
                          {t("our")}
                        </span>{" "}
                        {t("value")}
                      </h2>
                    </div>
                    <p>{t("our_values_description")}</p>
                  </div>
                </div>
                {/* TITLE END */}
                <div
                  className="video-section-full bg-no-repeat bg-cover overlay-wraper"
                  style={{ backgroundImage: "url(" + img1 + ")" }}
                >
                  <div className="overlay-main bg-black opacity-07" />
                  <div className="video-section-inner">
                    <div className="video-section-content">
                      <div className="video-section-left">
                        <button
                          type="button"
                          className="play-now"
                          data-toggle="modal"
                          data-target="#myModal2"
                        >
                          <i className="icon fa fa-play" />
                          <span className="ripple" />
                        </button>
                      </div>
                      <div className="video-section-right">
                        <NavLink
                          to={"#"}
                          className="font-weight-600 text-uppercase"
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          {t("video_presentation")}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="mt-count m-b30 text-white mt-icon-box-wraper center bg-dark p-a20">
                  <div className="counter font-30 font-weight-800 m-b15 text-primary">
                    <CountUp end={27} duration={5} />
                  </div>
                  <h4 className="m-tb0">{t("active_experts")}</h4>
                </div>
                <div className="mt-count m-b30 text-white mt-icon-box-wraper center bg-dark p-a20">
                  <div className="counter font-30 font-weight-800 m-b15 text-primary">
                    <CountUp end={142} duration={5} />
                  </div>
                  <h4 className="m-tb0">{t("satisfied_clients")}</h4>
                </div>
                <div className="mt-count m-b30 text-white mt-icon-box-wraper center bg-dark p-a20">
                  <div className="counter font-30 font-weight-800 m-b15 text-primary">
                    <CountUp end={170} duration={5} />
                  </div>
                  <h4 className="m-tb0">{t("projects_completed")}</h4>
                </div>
              </div>
              <div className="col-md-5 col-sm-6">
                <div className="p-b0">
                  <div className="mt-box">
                    <h3 className="m-t0">
                      <span className="font-weight-100">{t("we_have")}</span>
                      <span className="text-primary"> {t("years_experience")}</span>{" "}
                      {t("in_architecture")}
                    </h3>
                  </div>
                  <span className="progressText text-black">
                    <b>{t("architecture")}</b>
                  </span>
                  <div className="progress mt-probar-1 m-b30 m-t10">
                    <div
                      className="progress-bar bg-primary"
                      role="progressbar"
                      aria-valuenow={85}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <span
                        className="popOver"
                        data-toggle="tooltips"
                        data-placement="top"
                        title="85%"
                      />
                    </div>
                  </div>
                  <span className="progressText text-black">
                    <b>{t("construction")}</b>
                  </span>
                  <div className="progress mt-probar-1 m-b30 m-t10">
                    <div
                      className="progress-bar bg-primary"
                      role="progressbar"
                      aria-valuenow={78}
                      aria-valuemin={10}
                      aria-valuemax={100}
                    >
                      <span
                        className="popOver"
                        data-toggle="tooltips"
                        data-placement="top"
                        title="78%"
                      />
                    </div>
                  </div>
                  <span className="progressText text-black">
                    <b>{t("interior_design")}</b>
                  </span>
                  <div className="progress mt-probar-1 m-b30 m-t10">
                    <div
                      className="progress-bar bg-primary"
                      role="progressbar"
                      aria-valuenow={65}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <span
                        className="popOver"
                        data-toggle="tooltips"
                        data-placement="top"
                        title="65%"
                      />
                    </div>
                  </div>
                  <span className="progressText text-black">
                    <b>{t("commercial_projects")}</b>
                  </span>
                  <div className="progress mt-probar-1 m-b30 m-t10">
                    <div
                      className="progress-bar bg-primary"
                      role="progressbar"
                      aria-valuenow={55}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <span
                        className="popOver"
                        data-toggle="tooltips"
                        data-placement="top"
                        title="55%"
                      />
                    </div>
                  </div>
                  <span className="progressText text-black">
                    <b>{t("residential_projects")}</b>
                  </span>
                  <div className="progress mt-probar-1 m-b30 m-t10">
                    <div
                      className="progress-bar bg-primary"
                      role="progressbar"
                      aria-valuenow={40}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <span
                        className="popOver"
                        data-toggle="tooltips"
                        data-placement="top"
                        title="40%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="myModal2" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <ReactPlayer url="https://vimeo.com/34741214" />
          </div>
        </div>
      </div>
    </>
  );
};

export default OurValue;
