import React from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";

class Banner extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <div
          className="mt-bnr-inr overlay-wraper bg-parallax bg-top-center"
          data-stellar-background-ratio="0.5"
          style={{ backgroundImage: `url(${this.props.bgimage})` }}
        >
          <div className="overlay-main bg-black opacity-07" />
          <div className="container">
            <div className="mt-bnr-inr-entry">
              <div className="banner-title-outer">
                <div className="banner-title-name">
                  <h2 className="m-b0">{this.props.title}</h2>
                </div>
              </div>
              <div>
                <ul className="mt-breadcrumb breadcrumb-style-2">
                  <li>
                    <NavLink to={"./"}>{t("breadcrumb_home")}</NavLink>
                  </li>
                  <li>{this.props.pagename}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(Banner);
