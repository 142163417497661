import React, { useEffect, useState } from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Slider from "./../Elements/Slider";
import SliderRTL from "./../Elements/Slider-rtl";
import About from "./../Elements/About";
import OurValue from "./../Elements/OurValue";
import OurMission from "./../Elements/OurMission";
import Callus from "./../Elements/Callus";
import OurProject from "./../Elements/OurProject";
import Testimonials from "./../Elements/Testimonials";
import Faq from "./../Elements/Faq";

const Home = () => {
  const [isRTL, setIsRTL] = useState(
    document.documentElement.getAttribute("dir") === "rtl"
  );

  useEffect(() => {
    const handleDirChange = () => {
      setIsRTL(document.documentElement.getAttribute("dir") === "rtl");
    };

    const observer = new MutationObserver(handleDirChange);
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["dir"],
    });

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <Header />
      <div className="page-content">
        {isRTL ? <SliderRTL /> : <Slider />}
        <About />
        <OurValue />
        <OurMission />
        <Callus />
        <OurProject />
        <Faq />
        <Testimonials />
      </div>
      <Footer />
    </>
  );
};

export default Home;
