import React from "react";
import { withTranslation } from "react-i18next";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import AboutCompany from "../Elements/AboutCompany";
import Specialization4 from "../Elements/Specialization4";
import OurServices4 from "../Elements/OurServices";
import OurServices from "../Elements/OurServices4";
import OurProject3 from "../Elements/OurProject3";
import Faq from "../Elements/Faq";

var bnrimg = require("./../../images/banner/1.jpg");

class Services extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title={t("services_banner_title")}
            pagename={t("services_page_name")}
            bgimage={bnrimg}
          />
          <AboutCompany />
          <Specialization4 />
          <OurServices4 />
          <OurServices />
          <OurProject3 />
          <Faq />
        </div>
        <Footer />
      </>
    );
  }
}

export default withTranslation()(Services);
