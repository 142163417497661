import React from 'react';
// import { NavLink } from 'react-router-dom';

const services = [
  {
    count: 1,
    title: `Interior Design`,
    icon: require("./../../images/icon/planning.png"),
    image: require("./../../images/lash/interior.jpg"),
    description:
      "We transform interior spaces to be functional and safe by defining space requirements and choosing basic and decorative elements. We follow formal rules in interior design that include colors, lighting, and materials. We keep them balanced which is the key to creating an aesthetic design for the place.",
  },
  {
    count: 2,
    title: "Fit-out",
    icon: require("./../../images/icon/hammer-1.png"),
    image: require("./../../images/lash/worley-office-fitout.jpg"),
    description:
      "We identify a specialized team to study the interior/exterior spaces to ensure that the work is completed in a timely manner. Applying the highest standards, we ensure the processes that the client may need to prepare the project.",
  },
  {
    count: 3,
    title: "Manufacturing",
    icon: require("./../../images/icon/crane-1.png"),
    image: require("./../../images/services/3.jpg"),
    description:
      "We improve your space with luxury furniture that is designed especially for you. We put our expertise in modern interior solutions that may combine metal and wood to appear in a product that transforms your home/space into a haven of elegance.",
  },
  {
    count: 4,
    title: "Maintenance",
    icon: require("./../../images/icon/cogwheels.png"),
    image: require("./../../images/services/2.jpg"),
    description:
      "We specialize in comprehensive building maintenance and cleaning services executed with the highest level of professionalism. Our team has undergone rigorous training in the utilization of cleaning and protective materials sourced from European factories.",
  },
  {
    count: 5,
    title: "Quality Management",
    icon: require("./../../images/icon/calculations.png"),
    image: require("./../../images/lash/quality.jpg"),
    description:
      "We apply the highest standards to ensure that each project deliverable used in the project is within the work plan. We address immediate concerns and supervise during project implementation to achieve quality project deliverables in accordance with the project schedule.",
  },
];

class Services extends React.Component {
    render() {
        return (
            <>
                <div className="section-full bg-white slider-half-part">
                    <div className="container">
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                                {services.map((item, index) => (
                                    <div className="col-md-4 col-sm-4 m-b30" key={index}>
                                        <div className="half-blocks">
                                            <div className="mt-icon-box-wraper center m-b30">
                                                <div className="half-block-content icon-count-2 p-a30 p-tb50">
                                                    <span className="icon-count-number">{item.count}</span>
                                                    <div className="mt-icon-box-lg radius bg-gray m-b20 scale-in-center">
                                                        <span className="icon-cell"><img src={item.icon} alt=""/></span>
                                                    </div>
                                                    <div className="icon-content">
                                                        <h4 className="mt-tilte m-b25">{item.title}</h4>
                                                        <p>{item.description}</p>
                                                        {/* <NavLink to={"/services"} className="site-button-link" data-hover="Read More">Read More <i className="fa fa-angle-right arrow-animation" /></NavLink> */}
                                                    </div>
                                                </div>
                                                <div className="bg-cover  half-blocks-bg" style={{ backgroundImage: 'url(' + item.image + ')' }} />
                                            </div>
                                        </div>
                                    </div>
                                        
                                    ))}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Services;