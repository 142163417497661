import React from "react";
import { useTranslation } from "react-i18next";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";

var bnrimg = require("./../../images/banner/2.jpg");

const ContactUs = () => {
  const { t } = useTranslation(); // Use the translation hook

  return (
    <>
      <Header />
      <div className="page-content">
        <Banner
          title={t("Putting a plan to action, to assure your satisfaction!")}
          pagename={t("Contact Us")}
          bgimage={bnrimg}
        />

        {/* SECTION CONTENT START */}
        <div className="section-full p-tb80 inner-page-padding">
          {/* LOCATION BLOCK*/}
          <div className="container">
            {/* GOOGLE MAP & CONTACT FORM */}
            <div className="section-content">
              {/* CONTACT FORM*/}
              <div className="row">
                <div className="col-md-8 col-sm-6">
                  <form
                    className="contact-form cons-contact-form"
                    method="post"
                    action="form-handler.php"
                  >
                    <div className="contact-one m-b30">
                      {/* TITLE START */}
                      <div className="section-head">
                        <div className="mt-separator-outer separator-left">
                          <div className="mt-separator">
                            <h2 className="text-uppercase sep-line-one">
                              <span className="font-weight-300 text-primary">
                                {t("Get")}
                              </span>{" "}
                              {t("In touch")}
                            </h2>
                          </div>
                        </div>
                      </div>
                      {/* TITLE END */}
                      <div className="form-group">
                        <input
                          name="username"
                          type="text"
                          required
                          className="form-control"
                          placeholder={t("Name")}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="email"
                          type="text"
                          className="form-control"
                          required
                          placeholder={t("Email")}
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          name="message"
                          rows={4}
                          className="form-control"
                          required
                          placeholder={t("Message")}
                        />
                      </div>
                      <div className="text-right">
                        <button
                          name="submit"
                          type="submit"
                          value="Submit"
                          className="site-button btn-effect"
                        >
                          {t("Submit")}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="contact-info m-b30">
                    {/* TITLE START */}
                    <div className="section-head">
                      <div className="mt-separator-outer separator-left">
                        <div className="mt-separator">
                          <h2 className="text-uppercase sep-line-one">
                            <span className="font-weight-300 text-primary">
                              {t("Contact")}
                            </span>{" "}
                            {t("Info")}
                          </h2>
                        </div>
                      </div>
                    </div>
                    {/* TITLE END */}
                    <div className="bg-dark p-a20 text-white">
                      <div className="mt-icon-box-wraper left p-b40">
                        <div className="icon-xs">
                          <i className="fa fa-phone" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0 font-weight-500">
                            {t("Phone number")}
                          </h5>
                          <p>(+966) 50-776-9189</p>
                          <p>(+966) 55-487-8434</p>
                        </div>
                      </div>
                      <div className="mt-icon-box-wraper left p-b40">
                        <div className="icon-xs">
                          <i className="fa fa-envelope" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0 font-weight-500">
                            {t("Email address")}
                          </h5>
                          <p>info@AlyaNajd.com</p>
                        </div>
                      </div>
                      <div className="mt-icon-box-wraper left">
                        <div className="icon-xs">
                          <i className="fa fa-map-marker" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0 font-weight-500">
                            {t("Address info")}
                          </h5>
                          <p>
                            Laysen Valley, Umm Al Hamam Al Gharbi, Riyadh 12329
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SECTION CONTENT END */}
      </div>

      <Footer />
    </>
  );
};

export default ContactUs;
