import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import About2 from "./../Elements/About2";
import Specialization4 from "./../Elements/Specialization4";
import AboutSummary from "./../Elements/AboutSummary";
import { withTranslation } from "react-i18next";

var bnrimg = require("./../../images/background/alyanjd.png");

class About extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title={t("about_banner_title")}
            pagename={t("about_page_name")}
            bgimage={bnrimg}
          />
          <About2 />
          <Specialization4 />
          <AboutSummary />
        </div>
        <Footer />
      </>
    );
  }
}

export default withTranslation()(About);
