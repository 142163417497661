import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const images = [
  require("./../../images/gallery/pic1.jpg"),
  require("./../../images/gallery/pic8.jpg"),
  require("./../../images/gallery/pic9.jpg"),
  require("./../../images/gallery/pic6.jpg"),
  require("./../../images/gallery/pic2.jpg"),
];

var bnr1 = require("./../../images/pattren.png");

class About extends Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }
    loadScript(`${process.env.PUBLIC_URL}/assets/js/masonary.js`);
  }

  render() {
    const { t } = this.props;
    const isRTL = document.body.dir === "rtl";

    const options = {
      loop: true,
      autoplay: true,
      margin: 30,
      nav: false,
      dots: true,
      rtl: isRTL, // Apply RTL direction
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: { items: 1 },
        991: { items: 1 },
      },
    };

    return (
      <>
        <div
          className="section-full mobile-page-padding p-t80 p-b30 bg-dark bg-repeat square_shape2 bg-moving"
          style={{ backgroundImage: "url(" + bnr1 + ")" }}
        >
          <div className="container" style={{ direction: "ltr" }}>
            {/* TITLE START */}
            <div className="section-head">
              <div className="mt-separator-outer separator-center">
                <div className="mt-separator">
                  <h2 className="text-white text-uppercase sep-line-one">
                    <span className="font-weight-300 text-primary">
                      {t("about")}
                    </span>{" "}
                    {t("about_company")}
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
              <div className="row">
                <div className="col-md-9 col-sm-12">
                  <OwlCarousel
                    className={`owl-carousel about-home owl-btn-vertical-center ${
                      isRTL ? "owl-rtl" : ""
                    }`}
                    {...options}
                  >
                    {images.map((item, index) => (
                      <div className="item" key={index}>
                        <div className="mt-img-effect zoom-slow">
                          <NavLink to="/about">
                            <img src={item} alt="" />
                          </NavLink>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
                <div className="col-md-3 col-sm-12">
                  <div className="about-home-right bg-white p-a30">
                    <h3 className="m-t0">
                      <span className="font-weight-100">
                        {t("dedicated_to_excellence")}
                      </span>
                    </h3>
                    <p>
                      <strong>{t("alya_najd_story")}</strong>
                    </p>
                    <p>{t("team_methodology")}</p>

                    <div className="text-black">
                      <h4 className="mt-tilte m-t0">{t("brochure")}</h4>
                      <p>{t("learn_more_brochure")}</p>
                      <a
                        href={require("../../profile/CompanyProfile-ALYANAJD.pdf")}
                        className="site-button-secondry btn-half"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{t("view_brochure_arabic")}</span>
                      </a>

                      <a
                        href={require("../../profile/CompanyProfile-ALYANAJD-en.pdf")}
                        className="m-t10 site-button-secondry btn-half"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{t("view_brochure_english")}</span>
                      </a>
                    </div>

                    <br />
                    <div className="text-black">
                      <NavLink
                        to="/about"
                        className="site-button-link"
                        data-hover={t("read_more")}
                      >
                        {t("read_more")}{" "}
                        <i className="fa fa-angle-right arrow-animation" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hilite-title text-left p-l50 text-uppercase hilite-dark">
            <strong>{t("about")}</strong>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(About);
