import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();

  return (
    <div className="section-full p-t80 p-b50 bg-white inner-page-padding">
      <div className="container">
        {/* IMAGE CAROUSEL START */}
        <div className="section-content">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              {/* TITLE START */}
              <div className="section-head">
                <div className="mt-separator-outer separator-left">
                  <div className="mt-separator">
                    <h2 className="text-uppercase sep-line-one">
                      <span className="font-weight-300 text-primary">
                        {t("some")}
                      </span>{" "}
                      {t("faq")}
                    </h2>
                  </div>
                </div>
              </div>
              {/* TITLE END */}
              <div className="section-content p-b30">
                <div className="mt-accordion acc-bg-gray" id="accordion5">
                  <div className="panel mt-panel">
                    <div className="acod-head acc-actives">
                      <h6 className="acod-title">
                        <a
                          data-toggle="collapse"
                          href="#collapseOne5"
                          data-parent="#accordion5"
                        >
                          {t("faq_payment_options")}
                          <span className="indicator">
                            <i className="fa" />
                          </span>
                        </a>
                      </h6>
                    </div>
                    <div id="collapseOne5" className="acod-body collapse in">
                      <div className="acod-content p-tb15">
                        <p>{t("faq_payment_options_answer")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="panel mt-panel">
                    <div className="acod-head">
                      <h6 className="acod-title">
                        <a
                          data-toggle="collapse"
                          href="#collapseTwo5"
                          className="collapsed"
                          data-parent="#accordion5"
                        >
                          {t("faq_live_preview")}
                          <span className="indicator">
                            <i className="fa" />
                          </span>
                        </a>
                      </h6>
                    </div>
                    <div id="collapseTwo5" className="acod-body collapse">
                      <div className="acod-content p-tb15">
                        <p>{t("faq_live_preview_answer")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="panel mt-panel">
                    <div className="acod-head">
                      <h6 className="acod-title">
                        <a
                          data-toggle="collapse"
                          href="#collapseThree5"
                          className="collapsed"
                          data-parent="#accordion5"
                        >
                          {t("faq_choose_service")}
                          <span className="indicator">
                            <i className="fa" />
                          </span>
                        </a>
                      </h6>
                    </div>
                    <div id="collapseThree5" className="acod-body collapse">
                      <div className="acod-content p-tb15">
                        <p>{t("faq_choose_service_answer")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6">
              {/* TITLE START */}
              <div className="section-head">
                <div className="mt-separator-outer separator-left">
                  <div className="mt-separator">
                    <h2 className="text-uppercase sep-line-one ">
                      <span className="font-weight-300 text-primary">
                        {t("our")}
                      </span>{" "}
                      {t("solution")}
                    </h2>
                  </div>
                </div>
              </div>
              {/* TITLE END */}
              <div className="row">
                <div className="col-md-6">
                  <div className="mt-icon-box-wraper p-a25 m-b30 onhover-box-shadow bg-gray center">
                    <div className="mt-icon-box-sm inline-icon text-primary m-b20 radius bg-secondry scale-in-center">
                      <span className="icon-cell text-primary">
                        <i className="flaticon-sketch" />
                      </span>
                    </div>
                    <div className="icon-content text-black">
                      <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">
                        {t("assistance")}
                      </h4>
                      <p>{t("assistance_text")}</p>
                      <NavLink
                        to="/about"
                        className="site-button-link"
                        data-hover={t("read_more")}
                      >
                        {t("read_more")}{" "}
                        <i className="fa fa-angle-right arrow-animation" />
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mt-icon-box-wraper p-a25 m-b30 onhover-box-shadow bg-dark center">
                    <div className="mt-icon-box-sm inline-icon text-primary m-b20 radius bg-secondry scale-in-center">
                      <span className="icon-cell text-primary">
                        <i className="flaticon-ui" />
                      </span>
                    </div>
                    <div className="icon-content text-white">
                      <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">
                        {t("financing")}
                      </h4>
                      <p>{t("financing_text")}</p>
                      <NavLink
                        to="/about"
                        className="site-button-link yellow"
                        data-hover={t("read_more")}
                      >
                        {t("read_more")}{" "}
                        <i className="fa fa-angle-right arrow-animation" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FAQ */}
      </div>
    </div>
  );
};

export default Faq;
