import React from "react";
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Testimonials = () => {
  const { t } = useTranslation();

  const testimonials = [
    { image: require("./../../images/testimonials/pic1.jpg"), key: 0 },
    { image: require("./../../images/testimonials/pic2.jpg"), key: 1 },
    { image: require("./../../images/testimonials/pic3.jpg"), key: 2 },
    { image: require("./../../images/testimonials/pic4.jpg"), key: 3 },
    { image: require("./../../images/testimonials/pic5.jpg"), key: 4 },
  ];

  const options = {
    loop: true,
    autoplay: true,
    margin: 30,
    nav: false,
    dots: true,
    responsive: {
      0: { items: 1 },
      991: { items: 2 },
    },
  };

  return (
    <div
      className="section-full mobile-page-padding p-t80 p-b50 square_shape2 bg-cover"
      style={{
        backgroundImage: `url(${require("./../../images/background/bg6.jpg")})`,
      }}
    >
      <div className="container" style={{ direction: "ltr" }}>
        <div className="section-content">
          <div className="section-head">
            <div className="mt-separator-outer separator-center">
              <div className="mt-separator">
                <h2 className="text-uppercase sep-line-one">
                  <span className="font-weight-300 text-primary">
                    {t("client")}
                  </span>{" "}
                  {t("testimonials")}
                </h2>
              </div>
            </div>
          </div>
          <OwlCarousel className="owl-carousel testimonial-home" {...options}>
            {testimonials.map((item) => (
              <div key={item.key} className="item">
                <div className="testimonial-2 m-a30 hover-animation-1">
                  <div className="block-shadow bg-white p-a30">
                    <div className="testimonial-detail clearfix">
                      <h4 className="testimonial-name m-b5">
                        {t(`testimonials_${item.key}_reviewername`)} -
                      </h4>
                      <span className="testimonial-position">
                        {t(`testimonials_${item.key}_position`)}
                      </span>
                    </div>
                    <div className="testimonial-text">
                      <span className="fa fa-quote-right" />
                      <p>{t(`testimonials_${item.key}_review`)}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
      <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
        <strong>{t("clients")}</strong>
      </div>
    </div>
  );
};

export default Testimonials;
