import React from "react";
import { useTranslation } from "react-i18next";

// Function to dynamically load stylesheets
function loadStyleSheet(href, id) {
  let link = document.getElementById(id);
  if (link) {
    link.href = href;
  } else {
    link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = href;
    link.id = id;
    document.head.appendChild(link);
  }
}

const Switcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);

    const isRTL = lng === "ar";
    document.documentElement.dir = isRTL ? "rtl" : "ltr";
    document.documentElement.lang = lng;

    // Dynamically load the correct stylesheets
    const bootstrapCSS = isRTL
      ? "/assets/css/bootstrap-rtl.min.css"
      : "/assets/css/bootstrap.min.css";
    const styleCSS = isRTL
      ? "/assets/css/style-rtl.css"
      : "/assets/css/style.css";

    // Load stylesheets
    loadStyleSheet(bootstrapCSS, "bootstrap-css");
    loadStyleSheet(styleCSS, "style-css");
  };

  return (
    <div>
     
        <button className="site-button " onClick={() => changeLanguage("en")}>
          English
        </button>
        <button className="site-button" onClick={() => changeLanguage("ar")}>
          العربية
        </button>
      
    </div>
  );
};

export default Switcher;
