import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const filters = [
  { label: "filter_construction", filter: ".cat-1" },
  { label: "filter_renovation", filter: ".cat-2" },
  { label: "filter_outdoor", filter: ".cat-3" },
  { label: "filter_interiors", filter: ".cat-4" },
  { label: "filter_consulting", filter: ".cat-5" },
];

const projects = [
  {
    image: require("./../../images/services/service-projects/shooping-mall.jpg"),
    title: "project_shopping_mall",
    address: "address_muscat_oman",
    filter: "cat-1",
  },
  {
    image: require("./../../images/services/service-projects/hospital.jpg"),
    title: "project_hospital",
    address: "address_muscat_oman",
    filter: "cat-2",
  },
  {
    image: require("./../../images/services/service-projects/garden-house.jpg"),
    title: "project_garden_house",
    address: "address_muscat_oman",
    filter: "cat-3",
  },
  {
    image: require("./../../images/services/service-projects/villa.jpg"),
    title: "project_villa",
    address: "address_muscat_oman",
    filter: "cat-4",
  },
  {
    image: require("./../../images/services/service-projects/road-construction.jpg"),
    title: "project_road_construction",
    address: "address_muscat_oman",
    filter: "cat-5",
  },
  {
    image: require("./../../images/services/service-projects/bridge.jpg"),
    title: "project_bridge",
    address: "address_muscat_oman",
    filter: "cat-4",
  },
  {
    image: require("./../../images/services/service-projects/building-ren.jpg"),
    title: "project_building_renovation",
    address: "address_muscat_oman",
    filter: "cat-3",
  },
  {
    image: require("./../../images/services/service-projects/dam-construction.jpg"),
    title: "project_dam_construction",
    address: "address_muscat_oman",
    filter: "cat-2",
  },
];

var img1 = require("./../../images/background/bg5.jpg");

const OurProject3 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="section-full p-t80 p-b50 bg-parallax bg-cover mobile-page-padding"
        data-stellar-background-ratio="0.5"
        style={{ backgroundImage: "url(" + img1 + ")" }}
      >
        <div className="container">
          {/* TITLE START */}
          <div className="section-head">
            <div className="mt-separator-outer separator-center">
              <div className="mt-separator text-white">
                <h2 className="text-uppercase sep-line-one ">
                  <span className="font-weight-300 text-primary">
                    {t("our_project")}
                  </span>
                </h2>
              </div>
            </div>
          </div>
          {/* TITLE END */}
          {/* PAGINATION START */}
          <div className="filter-wrap p-b15 center">
            <ul className="masonry-filter link-style  text-uppercase white">
              <li className="active">
                <NavLink to={"#"} data-filter="*" data-hover={t("filter_all")}>
                  {t("filter_all")}
                </NavLink>
              </li>
              {filters.map((item, index) => (
                <li key={index}>
                  <NavLink
                    to={"#"}
                    data-filter={item.filter}
                    data-hover={t(item.label)}
                  >
                    {t(item.label)}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          {/* PAGINATION END */}
          {/* GALLERY CONTENT START */}
          <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
            {projects.map((item, index) => (
              <div
                key={index}
                className={`${item.filter} masonry-item col-md-3 col-sm-6 m-b30`}
              >
                <div className="mt-box image-hover-block">
                  <div className="mt-thum-bx">
                    <img src={item.image} alt="" />
                  </div>
                  <div className="mt-info p-t20 text-white">
                    <h4 className="mt-tilte m-b10 m-t0">{t(item.title)}</h4>
                    <p className="m-b0">{t(item.address)}</p>
                  </div>
                  {/* <NavLink to="/project-detail"></NavLink> */}
                </div>
              </div>
            ))}
          </div>
          {/* GALLERY CONTENT END */}
        </div>
      </div>
    </>
  );
};

export default OurProject3;
