import React from "react";
import { withTranslation } from "react-i18next";

var img1 = require("./../../images/pattren.png");
var img2 = require("./../../images/pattrens-light.png");

class AboutSummary extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }
    loadScript("./assets/js/masonary.js");
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <div
          className="section-full p-t80 p-b80 bg-dark bg-repeat square_shape2 inner-page-padding"
          style={{ backgroundImage: "url(" + img1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="mt-separator-outer separator-left">
                <div className="mt-separator text-white">
                  <h2 className="text-uppercase sep-line-one ">
                    <span className="font-weight-300 text-primary">
                      {t("about_summary")}
                    </span>
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content our-story">
              {[
                {
                  year: "2016-17",
                  title: t("inception_early_projects"),
                  description: t("inception_early_projects_desc"),
                  image: require("./../../images/background/LogoMockup.jpg"),
                },
                {
                  year: "2018-19",
                  title: t("expansion_growth"),
                  description: t("expansion_growth_desc"),
                  image: require("./../../images/background/alyanjd.jpeg"),
                },
                {
                  year: "2020-21",
                  title: t("innovations_new_services"),
                  description: t("innovations_new_services_desc"),
                  image: require("./../../images/background/new.jpg"),
                },
                {
                  year: "2022-23",
                  title: t("sustainability_future_goals"),
                  description: t("sustainability_future_goals_desc"),
                  image: require("./../../profile/PIC/2023.jpg"),
                },
              ].map((item, index) => (
                <div className="row" key={index}>
                  <div className="col-md-4 col-sm-6">
                    <div className="our-story-pic-block">
                      <div className="mt-media our-story-pic">
                        <img src={item.image} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-6">
                    <div
                      className="mt-box our-story-detail bg-moving bg-cover"
                      style={{ backgroundImage: "url(" + img2 + ")" }}
                    >
                      <h4 className="text-uppercase m-b20">{item.year}</h4>
                      <h4 className="m-b20">{item.title}</h4>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(AboutSummary);
