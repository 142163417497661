import React from "react";
import { useTranslation } from "react-i18next";

const services = [
  {
    count: 1,
    titleKey: "services_interior_design",
    icon: require("./../../images/icon/planning.png"),
    image: require("./../../images/lash/interior.jpg"),
    descriptionKey: "services_interior_design_desc",
  },
  {
    count: 2,
    titleKey: "services_fit_out",
    icon: require("./../../images/icon/hammer-1.png"),
    image: require("./../../images/lash/worley-office-fitout.jpg"),
    descriptionKey: "services_fit_out_desc",
  },
  {
    count: 3,
    titleKey: "services_manufacturing",
    icon: require("./../../images/icon/crane-1.png"),
    image: require("./../../images/services/3.jpg"),
    descriptionKey: "services_manufacturing_desc",
  },
  {
    count: 4,
    titleKey: "services_maintenance",
    icon: require("./../../images/icon/cogwheels.png"),
    image: require("./../../images/services/2.jpg"),
    descriptionKey: "services_maintenance_desc",
  },
  {
    count: 5,
    titleKey: "services_quality_management",
    icon: require("./../../images/icon/calculations.png"),
    image: require("./../../images/lash/quality.jpg"),
    descriptionKey: "services_quality_management_desc",
  },
];

var img1 = require("./../../images/pattren.png");

const OurServices = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="section-full mobile-page-padding p-b50 square_shape2">
        <div className="section-content">
          <div
            className="Service-half-top p-t80 bg-dark bg-moving"
            style={{ backgroundImage: "url(" + img1 + ")" }}
          >
            <div className="container">
              {/* TITLE START */}
              <div className="section-head text-white">
                <div className="mt-separator-outer separator-left">
                  <div className="mt-separator">
                    <h2 className="text-white text-uppercase sep-line-one">
                      <span className="font-weight-300 text-primary">
                        {t("our_specialization")}
                      </span>
                    </h2>
                  </div>
                </div>
                <h3>{t("our_specialization_description")}</h3>
              </div>
              {/* TITLE END */}
            </div>
          </div>
          <div className="services-half-bottom">
            <div className="container">
              {/* IMAGE CAROUSEL START */}
              <div className="section-content">
                <div className="row">
                  {services.map((item, index) => (
                    <div className="col-md-4 col-sm-4 m-b30" key={index}>
                      <div className="half-blocks">
                        <div className="mt-icon-box-wraper center m-b30">
                          <div className="half-block-content icon-count-2 p-a30 p-tb50">
                            <span className="icon-count-number">
                              {item.count}
                            </span>
                            <div className="mt-icon-box-lg radius bg-gray m-b20 scale-in-center">
                              <span className="icon-cell">
                                <img src={item.icon} alt={t(item.titleKey)} />
                              </span>
                            </div>
                            <div className="icon-content">
                              <h4 className="mt-tilte m-b25">
                                {t(item.titleKey)}
                              </h4>
                              <p>{t(item.descriptionKey)}</p>
                              {/* <NavLink to={"/services"} className="site-button-link" data-hover="Read More">Read More <i className="fa fa-angle-right arrow-animation" /></NavLink> */}
                            </div>
                          </div>
                          <div
                            className="bg-cover  half-blocks-bg"
                            style={{
                              backgroundImage: "url(" + item.image + ")",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
          <strong>{t("our_specialization")}</strong>
        </div>
      </div>
    </>
  );
};

export default OurServices;
