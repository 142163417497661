import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css"; // Default global styles

// Function to dynamically load stylesheets
function loadStyleSheet(href, id) {
  let link = document.getElementById(id);
  if (link) {
    // Update existing stylesheet href
    link.href = href;
  } else {
    // Create a new link element if it doesn't exist
    link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = href;
    link.id = id;
    document.head.appendChild(link);
  }
}

// Function to set up language and direction
function setupLanguageAndStyles() {
  const lang = localStorage.getItem("language") || "en"; // Default to English
  const isRTL = lang === "ar";

  // Set the lang and dir attributes on the <html> tag
  document.documentElement.setAttribute("lang", lang);
  document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");

  // Dynamically load the correct stylesheets
  const bootstrapCSS = isRTL
    ? "/assets/css/bootstrap-rtl.min.css"
    : "/assets/css/bootstrap.min.css";
  const styleCSS = isRTL
    ? "/assets/css/style-rtl.css"
    : "/assets/css/style.css";

  // Load stylesheets
  loadStyleSheet(bootstrapCSS, "bootstrap-css");
  loadStyleSheet(styleCSS, "style-css");
}

// Initialize language and styles
setupLanguageAndStyles();

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);
