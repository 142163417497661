import React from "react";
import { useTranslation } from "react-i18next";

const services = [
  {
    count: 1,
    titleKey: "services_rooms_halls",
    flaticon: "flaticon-house",
    descriptionKey: "services_rooms_halls_desc",
  },
  {
    count: 2,
    titleKey: "services_renovation",
    flaticon: "flaticon-paint-1",
    descriptionKey: "services_renovation_desc",
  },
  {
    count: 3,
    titleKey: "services_construction",
    flaticon: "flaticon-crane",
    descriptionKey: "services_construction_desc",
  },
  {
    count: 4,
    titleKey: "services_interior",
    flaticon: "flaticon-interior",
    descriptionKey: "services_interior_desc",
  },
  {
    count: 5,
    titleKey: "services_professional_opinion",
    flaticon: "flaticon-chart",
    descriptionKey: "services_professional_opinion_desc",
  },
  {
    count: 6,
    titleKey: "services_accurate_engineering",
    flaticon: "flaticon-sketch",
    descriptionKey: "services_accurate_engineering_desc",
  },
  {
    count: 7,
    titleKey: "services_quality_management",
    flaticon: "flaticon-worker",
    descriptionKey: "services_quality_management_desc",
  },
  {
    count: 8,
    titleKey: "services_fit_out",
    flaticon: "flaticon-art-and-design",
    descriptionKey: "services_fit_out_desc",
  },
  {
    count: 9,
    titleKey: "services_manufacturing",
    flaticon: "flaticon-construction-and-tools",
    descriptionKey: "services_manufacturing_desc",
  },
  {
    count: 10,
    titleKey: "services_maintenance",
    flaticon: "flaticon-wrench",
    descriptionKey: "services_maintenance_desc",
  },
];

var img1 = require("./../../images/pattrens-light.png");
var img2 = require("./../../images/background/line.png");

const OurServices4 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="section-full p-t80 p-b50 bg-dark mobile-page-padding"
        style={{ backgroundImage: "url(" + img1 + ")" }}
      >
        <div className="container">
          {/* TITLE START */}
          <div className="section-head">
            <div className="mt-separator-outer separator-center">
              <div className="mt-separator text-white">
                <h2 className="text-uppercase sep-line-one ">
                  <span className="font-weight-300 text-primary">
                    {t("all_services")}
                  </span>{" "}
             
                </h2>
              </div>
            </div>
          </div>
          {/* TITLE END */}
          {/* IMAGE CAROUSEL START */}
          <div className="section-content">
            <div className="row">
              {services.map((item, index) => (
                <div key={index} className="col-md-4 col-sm-6">
                  <div className="mt-icon-box-wraper p-a30 center m-b30 bg-white">
                    <div
                      className="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-primary  scale-in-center bg-moving"
                      style={{ backgroundImage: "url(" + img2 + ")" }}
                    >
                      <span className="icon-cell text-secondry">
                        <i className={item.flaticon} />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">
                        {t(item.titleKey)}
                      </h4>
                      <p>{t(item.descriptionKey)}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurServices4;
