import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const filters = [
  { label: "interior_design", filter: ".cat-1" },
  { label: "fit_out", filter: ".cat-2" },
  { label: "manufacturing", filter: ".cat-3" },
  { label: "maintenance", filter: ".cat-4" },
  { label: "quality_management", filter: ".cat-5" },
];

const projects = [
  {
    image: require("./../../profile/OUR PROJECTS/Asila salon - design -1.jpg"),
    title: "asila_salon_design",
    address: "riyadh_saudi_arabia",
    width: "col-md-3",
    filter: "cat-1",
  },
  {
    image: require("./../../profile/OUR PROJECTS/avenal cafe - After.png"),
    title: "avenal_cafe",
    address: "jeddah_saudi_arabia",
    width: "col-md-3",
    filter: "cat-1",
  },
  {
    image: require("./../../profile/OUR PROJECTS/Private Villa - after -2.jpg"),
    title: "private_villa",
    address: "dammam_saudi_arabia",
    width: "col-md-3",
    filter: "cat-2",
  },
  {
    image: require("./../../profile/OUR PROJECTS/Quarter burger - design.jpg"),
    title: "quarter_burger",
    address: "khobar_saudi_arabia",
    width: "col-md-3",
    filter: "cat-2",
  },
  {
    image: require("./../../profile/PIC/Decorative Metal.jpg"),
    title: "decorative_metal",
    address: "riyadh_saudi_arabia",
    width: "col-md-3",
    filter: "cat-3",
  },
  {
    image: require("./../../profile/PIC/design furniture.jpg"),
    title: "design_furniture",
    address: "jeddah_saudi_arabia",
    width: "col-md-3",
    filter: "cat-3",
  },
  {
    image: require("./../../profile/PIC/False Ceiling.jpg"),
    title: "false_ceiling",
    address: "dammam_saudi_arabia",
    width: "col-md-3",
    filter: "cat-4",
  },
  {
    image: require("./../../profile/PIC/Maintenance.jpg"),
    title: "maintenance_works",
    address: "khobar_saudi_arabia",
    width: "col-md-3",
    filter: "cat-4",
  },
  {
    image: require("./../../profile/PIC/Quality management -1.jpg"),
    title: "quality_management_1",
    address: "jeddah_saudi_arabia",
    width: "col-md-3",
    filter: "cat-5",
  },
  {
    image: require("./../../profile/PIC/Quality management -2.jpg"),
    title: "quality_management_2",
    address: "riyadh_saudi_arabia",
    width: "col-md-3",
    filter: "cat-5",
  },
  {
    image: require("./../../profile/PIC/Quality management -3.jpg"),
    title: "quality_management_3",
    address: "dammam_saudi_arabia",
    width: "col-md-3",
    filter: "cat-5",
  },
  {
    image: require("./../../profile/PIC/Quality management -4.jpg"),
    title: "quality_management_4",
    address: "khobar_saudi_arabia",
    width: "col-md-3",
    filter: "cat-5",
  },
  {
    image: require("./../../profile/PIC/Quality management -5.jpg"),
    title: "quality_management_5",
    address: "riyadh_saudi_arabia",
    width: "col-md-3",
    filter: "cat-5",
  },
  {
    image: require("./../../profile/PIC/Cleaning.jpg"),
    title: "cleaning_services",
    address: "riyadh_saudi_arabia",
    width: "col-md-3",
    filter: "cat-4",
  },
  {
    image: require("./../../profile/PIC/Cleaning -1.jpg"),
    title: "cleaning_services_1",
    address: "jeddah_saudi_arabia",
    width: "col-md-3",
    filter: "cat-4",
  },
  {
    image: require("./../../profile/PIC/Cleaning -2.jpg"),
    title: "cleaning_services_2",
    address: "dammam_saudi_arabia",
    width: "col-md-3",
    filter: "cat-4",
  },
  {
    image: require("./../../profile/PIC/Cleaning -3.jpg"),
    title: "cleaning_services_3",
    address: "khobar_saudi_arabia",
    width: "col-md-3",
    filter: "cat-4",
  },
  {
    image: require("./../../profile/PIC/FITOUT.jpg"),
    title: "fitout_services",
    address: "jeddah_saudi_arabia",
    width: "col-md-3",
    filter: "cat-2",
  },
  {
    image: require("./../../profile/PIC/FITOUT -1.jpg"),
    title: "fitout_services_1",
    address: "riyadh_saudi_arabia",
    width: "col-md-3",
    filter: "cat-2",
  },
];

const OurProject = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", resolve);
        script.addEventListener("error", reject);
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    };

    loadScript("./assets/js/masonary.js");
  }, []);

  return (
    <>
      <div className="section-full mobile-page-padding p-t80 p-b30 square_shape2">
        <div className="container" style={{ direction: "ltr" }}>
          {/* TITLE START */}
          <div className="section-head">
            <div className="mt-separator-outer separator-left">
              <div className="mt-separator">
                <h2 className="text-uppercase sep-line-one">
                  <span className="font-weight-300 text-primary">
                    {t("our")}
                  </span>{" "}
                  {t("projects")}
                </h2>
              </div>
            </div>
          </div>
          {/* TITLE END */}
          <div className="section-content">
            <div className="portfolio-wrap row mfp-gallery product-stamp clearfix">
              {/* COLUMNS 1 */}
              <div
                className="stamp col-md-3 col-sm-6 m-b30"
                style={{ direction: "ltr" }}
              >
                <div className="bg-gray p-a30">
                  <div className="filter-wrap">
                    <ul className="filter-navigation masonry-filter text-uppercase">
                      <li className="active">
                        <NavLink to={"#"} data-filter="*" data-hover={t("all")}>
                          {t("all")}
                        </NavLink>
                      </li>
                      {filters.map((item, index) => (
                        <li key={index}>
                          <NavLink
                            to={"#"}
                            data-filter={item.filter}
                            data-hover={t(item.label)}
                          >
                            {t(item.label)}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* COLUMNS 2 */}
              {projects.map((item, index) => (
                <div
                  key={index}
                  className={`${item.filter} masonry-item ${item.width} col-sm-6 m-b30`}
                >
                  <div className="mt-box image-hover-block">
                    <div className="mt-thum-bx">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="mt-info p-t20 text-white">
                      <h4 className="mt-tilte m-b10 m-t0">{t(item.title)}</h4>
                      <p className="m-b0">{t(item.address)}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
          <strong>{t("projects")}</strong>
        </div>
      </div>
    </>
  );
};

export default OurProject;
